import React from "react"
import { navigate } from "gatsby"

// There is no default home page for the app site, so just navigate to the new quote page
const Home = () => {
  if (typeof window !== "undefined") {
    window.location.href = `/tenants/app/tool-a/signup${window.location.search}`
  }

  return <div />
}

export default Home
